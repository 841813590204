<template>
  <div class="authoring-wrapper">
    <div class="flex h-full">
      <ProgressBar
        :stepIndex="4"
        :surveyID="surveyID"
        stepsType="brand-fingerprint"
        :conciergeAssistance="false"
        :progressBarStatus="progressBarStatus"
      />
      <section class="wrapper">
        <a-breadcrumb class="breadcrum breadcrum--authoring">
          <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
            <a-breadcrumb-item
              ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
            >
            <a-breadcrumb-item
              ><a href="/products">Products</a></a-breadcrumb-item
            >
            <a-breadcrumb-item>PGNAT</a-breadcrumb-item>
          </template>
        </a-breadcrumb>
        <div class="wrapper__inner">
          <div class="page-title">
            <icon-base
              :width="32"
              :height="33"
              :viewBox1="32"
              :viewBox2="33"
              iconName="chart"
              iconColor="transparent"
              ><icon-chart-histogram
            /></icon-base>
            <div class="page-title__text">
              <h1>PGNAT</h1>
              <span>{{
                steps[currentStep - 1].replace(/([a-z])([A-Z])/g, "$1 $2")
              }}</span>
            </div>
          </div>

          <component
            :is="currentComponent"
            :surveyID="surveyID"
            :fingerprint="fingerprint"
            :attributes="attributes"
            :loading="loading"
            @btn-next-disabled="nextDisable = $event"
          />

          <StepNaviBar
            :nextDisable="nextDisable"
            :conciergeAssistance="true"
            @prevHandler="prevStep"
            @nextHandler="nextStep"
            stepsType="brand-fingerprint"
            @editable-survey-updated="handleEditableSurveyUpdate"
            @loading-survey-data="loadingBrands = $event"
            :loadingNextBtn="saveBrandsLoading"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import * as jsUtils from "@/utils/jsUtils.js";
import IconBase from "@/components/general/IconBase.vue";
import IconChartHistogram from "@/components/icons/IconChartHistogram.vue";
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar1.vue";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";

import BrandPositioning from "@/components/brandFingerprint/BrandPositioning.vue";
import ClientPrimes from "@/components/brandFingerprint/ClientPrimes.vue";
import CompetitorPrimes from "@/components/brandFingerprint/CompetitorPrimes.vue";
import TargetAndDistractorBrand from "@/components/brandFingerprint/TargetAndDistractorBrand.vue";
import * as wsUtils from "@/utils/wsUtils.js";

export default {
  name: "PgnatBrandFingerprint",
  components: {
    IconBase,
    IconChartHistogram,
    ProgressBar,
    StepNaviBar,
    BrandPositioning,
    ClientPrimes,
    CompetitorPrimes,
    TargetAndDistractorBrand,
  },
  props: {},
  data() {
    return {
      client: {},
      loading: true,
      user: null,
      brands: [],
      surveyID: null,
      loadingBrands: true,
      saveBrandsLoading: false,
      currentStep: 1,
      totalSteps: 4,
      steps: [
        "TargetAndDistractorBrand",
        "ClientPrimes",
        "CompetitorPrimes",
        "BrandPositioning",
      ],
      nextDisable: false,
      progressBarStatus: [false, false, false, false],
      fingerprint: {
        surveyID: null,
        clientDetails: {
          id: 0,
          brandName: "",
          assetDetails: [
            {
              id: 0,
              asset_description: "",
              upload_assets: "",
            },
            {
              id: 0,
              asset_description: "",
              upload_assets: "",
            },
            {
              id: 0,
              asset_description: "",
              upload_assets: "",
            },
            {
              id: 0,
              asset_description: "",
              upload_assets: "",
            },
          ],
        },
        competitorDetails: {
          id: 0,
          brandName: "",
          assetDetails: [],
        },
      },
      attributes: {
        surveyID: null,
        brand_Positioning: {
          id: 0,
          name: "",
          definitions: "",
          attributeList: [
            {
              id: 0,
              zoneID: null,
              ringID: null,
              attributeName: "",
            },
            {
              id: 0,
              zoneID: null,
              ringID: null,
              attributeName: "",
            },
            {
              id: 0,
              zoneID: null,
              ringID: null,
              attributeName: "",
            },
          ],
        },
        distractor_Positioning: {
          id: 0,
          name: "",
          definitions: "",
          attributeList: [
            {
              id: 0,
              zoneID: null,
              ringID: null,
              attributeName: "",
            },
            {
              id: 0,
              zoneID: null,
              ringID: null,
              attributeName: "",
            },
            {
              id: 0,
              zoneID: null,
              ringID: null,
              attributeName: "",
            },
          ],
        },
      },
    };
  },
  mixins: [surveyPriceMixin],
  computed: {
    currentComponent() {
      return this.steps[this.currentStep - 1];
    },
  },
  watch: {},
  methods: {
    nextStep() {
      window.scrollTo({
        top: 0,
      });
      if (this.currentStep < this.totalSteps) {
        this.currentStep++;
        this.progressBarStatus[this.currentStep - 2] = true;
        if (this.currentStep === 3) {
          this.addCompetitorAsset();
        }

        if (this.currentStep === 4) {
          this.saveBrandFingerprint();
        }
        this.getBrandFingerprintPrice();
      } else {
        this.checkDuplicateAttributes();
      }
    },
    addCompetitorAsset() {
      const validAssetsCount =
        this.fingerprint.clientDetails.assetDetails.filter(
          (asset) =>
            asset.asset_description !== "" && asset.upload_assets !== ""
        ).length;
      const validAssetsCount2 =
        this.fingerprint.competitorDetails.assetDetails.length;

      for (let i = 0; i < validAssetsCount - validAssetsCount2; i++) {
        this.fingerprint.competitorDetails.assetDetails.push({
          id: 0,
          asset_description: "",
          upload_assets: "",
        });
      }
    },
    saveBrandFingerprint() {
      this.fingerprint.surveyID = this.surveyID;
      this.fingerprint.clientDetails.assetDetails.filter(
        (asset) => asset.asset_description !== "" || asset.upload_assets !== ""
      );
      const data = {
        wsName: "SaveBrandFingerprintPGNAT",
        data: this.fingerprint,
      };

      const saveBrandFingerprint = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      saveBrandFingerprint.then(() => {});
    },
    checkDuplicateAttributes() {
      const findDuplicates = (list) => {
        const seen = new Set();
        const duplicates = new Set();
        list.forEach((item) => {
          const name = item.attributeName.trim();
          if (name && seen.has(name)) duplicates.add(name);
          else seen.add(name);
        });
        return Array.from(duplicates);
      };

      const brandDuplicates = findDuplicates(
        this.attributes.brand_Positioning.attributeList
      );
      if (brandDuplicates.length > 0) {
        console.log(
          "Duplicate attributeName found in brand_Positioning:",
          brandDuplicates
        );
        this.$notification["error"]({
          message: "Duplicate Attribute Name",
          description: `You have selected duplicate attribute name: ${brandDuplicates}`,
          placement: "bottomLeft",
          duration: 10,
        });
        return;
      }

      const distractorDuplicates = findDuplicates(
        this.attributes.distractor_Positioning.attributeList
      );
      if (distractorDuplicates.length > 0) {
        console.log(
          "Duplicate attributeName found in distractor_Positioning:",
          distractorDuplicates
        );
        return;
      }
      this.saveAttributes();
      this.$router.push({
        name: "brand-fingerprint-quotas",
        params: { id: this.surveyID },
      });
    },
    saveAttributes() {
      this.attributes.surveyID = this.surveyID;
      const data = {
        wsName: "SaveBrandFingerprintAttributes",
        data: this.attributes,
      };

      const saveAttributes = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      saveAttributes.then(() => {});
    },
    prevStep() {
      window.scrollTo({
        top: 0,
      });
      if (this.currentStep > 1) {
        this.currentStep--;
        this.progressBarStatus[this.currentStep - 1] = false;
      } else {
        this.$router.push({
          name: "stimuli-brand-fingerprint",
          params: { id: this.surveyID },
        });
      }
    },
    isStepActive(step) {
      return this.currentStep === step;
    },
    handleEditableSurveyUpdate(surveyData) {
      this.resetData();
      if (
        surveyData &&
        surveyData.pgnatDATA &&
        surveyData.pgnatDATA.clientDetails
      ) {
        this.fingerprint = surveyData.pgnatDATA;
      }

      if (
        surveyData &&
        surveyData.attribbuteData &&
        surveyData.attribbuteData.brand_Positioning
      ) {
        this.attributes = surveyData.attribbuteData;
      }

      this.loading = false;
    },
    resetData() {
      this.fingerprint = {
        surveyID: null,
        clientDetails: {
          id: 0,
          brandName: "",
          assetDetails: [
            {
              id: 0,
              asset_description: "",
              upload_assets: "",
            },
            {
              id: 0,
              asset_description: "",
              upload_assets: "",
            },
            {
              id: 0,
              asset_description: "",
              upload_assets: "",
            },
            {
              id: 0,
              asset_description: "",
              upload_assets: "",
            },
          ],
        },
        competitorDetails: {
          id: 0,
          brandName: "",
          assetDetails: [],
        },
      };
      this.attributes = {
        surveyID: null,
        brand_Positioning: {
          id: 0,
          name: "",
          definitions: "",
          attributeList: [
            {
              id: 0,
              zoneID: null,
              ringID: null,
              attributeName: "",
            },
            {
              id: 0,
              zoneID: null,
              ringID: null,
              attributeName: "",
            },
            {
              id: 0,
              zoneID: null,
              ringID: null,
              attributeName: "",
            },
          ],
        },
        distractor_Positioning: {
          id: 0,
          name: "",
          definitions: "",
          attributeList: [
            {
              id: 0,
              zoneID: null,
              ringID: null,
              attributeName: "",
            },
            {
              id: 0,
              zoneID: null,
              ringID: null,
              attributeName: "",
            },
            {
              id: 0,
              zoneID: null,
              ringID: null,
              attributeName: "",
            },
          ],
        },
      };
    },
  },
  mounted() {},
  created() {},
  activated() {
    window.scrollTo({
      top: 0,
    });
    this.loading = true;
    this.surveyID = this.$route.params.id;
    this.client = jsUtils.getCurClient();
    this.user = jsUtils.getUserInfo();
    this.currentStep = 1;
    this.progressBarStatus = [false, false, false, false];
  },
};
</script>

<style lang="scss" scoped>
.box-wrapper {
  min-height: 200px;
}
</style>
