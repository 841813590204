<template>
  <div class="box-wrapper">
    <StimuliTitle
      title="Please enter competitor assets for your distractor primes. You must upload the same number as your client. For accurate results, ensure that your images are debranded, black and white (unless testing your brand’s colour palette) and isolated. The more variety of competitors, the better."
    ></StimuliTitle>
    <ImageUpload
      v-for="(image, index) in fingerprint.competitorDetails.assetDetails"
      :key="index"
      :image="image"
      :surveyID="fingerprint.surveyID"
      @set-image="setImage($event, index)"
    ></ImageUpload>
  </div>
</template>

<script>
import StimuliTitle from "@/components/packTestSurvey/StimuliTitle.vue";
import ImageUpload from "@/components/brandFingerprint/ImageUpload.vue";

export default {
  name: "CompetitorPrimes",
  components: { StimuliTitle, ImageUpload },
  props: {
    fingerprint: Object,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {
    fingerprint: {
      handler() {
        this.validateInputs();
      },
      deep: true,
    },
  },
  methods: {
    setImage(imageURL, index) {
      this.fingerprint.competitorDetails.assetDetails[index].upload_assets =
        imageURL;
    },
    validateInputs() {
      const filledImages =
        this.fingerprint.competitorDetails.assetDetails.filter(
          (image) =>
            image.upload_assets.trim() !== "" &&
            image.asset_description.trim() !== ""
        );
      const isNextDisabled =
        filledImages.length <
        this.fingerprint.competitorDetails.assetDetails.length;
      this.$emit("btn-next-disabled", isNextDisabled);
    },
  },
  mounted() {
    this.validateInputs();
  },
  created() {},
  activated() {},
};
</script>

<style lang="scss" scoped></style>
