<template>
  <div class="box-wrapper">
    <StimuliTitle
      title="Please define your brand positioning. These are the attributes your brand strives to convey. You will also need to define an opposite positioning as a distractor. The more different, the better."
    ></StimuliTitle>
    <div class="box-wrapper__inner">
      <div class="input-group">
        <label> Client Brand Positioning </label>
        <div class="position-r">
          <Skeleton :borderRadius="16" v-if="loading"></Skeleton>
          <a-input v-model="attributes.brand_Positioning.name" />
        </div>
      </div>
      <div class="input-group">
        <label> Distractor Positioning </label>
        <div class="position-r">
          <Skeleton :borderRadius="16" v-if="loading"></Skeleton>
          <a-input v-model="attributes.distractor_Positioning.name" />
        </div>
      </div>
      <div class="input-group">
        <label> Positioning Definition </label>
        <div class="position-r">
          <Skeleton :borderRadius="16" v-if="loading"></Skeleton>
          <a-textarea
            v-model="attributes.brand_Positioning.definitions"
            :auto-size="{ minRows: 2 }"
          />
        </div>
      </div>
      <div class="input-group">
        <label> Positioning Definition </label>
        <div class="position-r">
          <Skeleton :borderRadius="16" v-if="loading"></Skeleton>
          <a-textarea
            v-model="attributes.distractor_Positioning.definitions"
            :auto-size="{ minRows: 2 }"
          />
        </div>
      </div>
      <div class="input-group">
        <label> Attribute options </label>

        <div
          v-for="(row, index) in rows"
          :key="index"
          class="flex gap-10 mb-20"
        >
          <!-- Zone Select -->
          <div class="position-r flex-1">
            <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
            <a-select
              v-model="row.zoneID"
              class="w-full"
              @change="onZoneOrRingChange(index, 'brand')"
              placeholder="Select Zone"
              :loading="loadingZones"
            >
              <a-select-option
                v-for="item in zoneList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>

          <!-- Ring Select -->
          <div class="position-r flex-1">
            <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
            <a-select
              class="w-full"
              v-model="row.ringID"
              @change="onZoneOrRingChange(index, 'brand')"
              placeholder="Select Ring"
              :loading="loadingRings"
            >
              <a-select-option
                v-for="item in ringList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>

          <!-- Attribute Select -->
          <div class="position-r flex-1">
            <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
            <a-select
              class="w-full"
              v-model="row.attributeID"
              :disabled="!attributeOptions[index].length"
              @change="onAttributeChange($event, index, 'brand')"
              placeholder="Select Attribute"
              :loading="loadingAttributes"
            >
              <a-select-opt-group
                v-for="(opt, optIndex) in attributeOptions[index]"
                :key="optIndex"
              >
                <span slot="label">{{ opt.AttributeLabel.toUpperCase() }}</span>
                <a-select-option
                  v-for="(item, itemIndex) in opt.attributeList"
                  :value="`${item.attributeName}_${item.ringID}_${item.zoneID}`"
                  :key="itemIndex"
                >
                  {{ item.attributeName }}
                </a-select-option>
              </a-select-opt-group>
            </a-select>
          </div>
        </div>
      </div>
      <div class="input-group">
        <label> Attribute options </label>

        <div
          v-for="(row, index) in distractorRows"
          :key="index"
          class="flex gap-10 mb-20"
        >
          <!-- Zone Select -->
          <div class="position-r flex-1">
            <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
            <a-select
              class="w-full"
              v-model="row.zoneID"
              @change="onZoneOrRingChange(index, 'distractor')"
              placeholder="Select Zone"
              :loading="loadingZones"
            >
              <a-select-option
                v-for="item in zoneList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>

          <!-- Ring Select -->
          <div class="position-r flex-1">
            <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
            <a-select
              class="w-full"
              v-model="row.ringID"
              @change="onZoneOrRingChange(index, 'distractor')"
              placeholder="Select Ring"
              :loading="loadingRings"
            >
              <a-select-option
                v-for="item in ringList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>

          <!-- Attribute Select -->
          <div class="position-r flex-1">
            <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
            <a-select
              class="w-full"
              v-model="row.attributeID"
              :disabled="!distractorAttributeOptions[index].length"
              @change="onAttributeChange($event, index, 'distractor')"
              placeholder="Select Attribute"
              :loading="loadingAttributes"
            >
              <a-select-opt-group
                v-for="(opt, optIndex) in distractorAttributeOptions[index]"
                :key="optIndex"
              >
                <span slot="label">{{ opt.AttributeLabel.toUpperCase() }}</span>
                <a-select-option
                  v-for="(item, itemIndex) in opt.attributeList"
                  :value="`${item.attributeName}_${item.ringID}_${item.zoneID}`"
                  :key="itemIndex"
                >
                  {{ item.attributeName }}
                </a-select-option>
              </a-select-opt-group>
            </a-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StimuliTitle from "@/components/packTestSurvey/StimuliTitle.vue";
import Skeleton from "@/components/general/Skeleton.vue";
import * as wsUtils from "@/utils/wsUtils.js";

export default {
  name: "BrandPositioning",
  components: { StimuliTitle, Skeleton },
  props: { attributes: Object, loading: Boolean },
  data() {
    return {
      showSkeleton: false,
      clientBrandPositioning: {
        brand: "",
        definition: "",
      },
      distractorPositioning: {
        distractor: "",
        definition: "",
      },
      zoneList: [],
      ringList: [],
      attributeOptions: [[], [], []],
      rows: [
        { zoneID: undefined, ringID: undefined, attributeID: undefined },
        { zoneID: undefined, ringID: undefined, attributeID: undefined },
        { zoneID: undefined, ringID: undefined, attributeID: undefined },
      ],
      distractorAttributeOptions: [[], [], []],
      distractorRows: [
        { zoneID: undefined, ringID: undefined, attributeID: undefined },
        { zoneID: undefined, ringID: undefined, attributeID: undefined },
        { zoneID: undefined, ringID: undefined, attributeID: undefined },
      ],
      loadingZones: true,
      loadingRings: true,
      loadingAttributes: false,
    };
  },
  computed: {},
  watch: {
    attributes: {
      handler() {
        this.validateInputs();
      },
      deep: true,
    },
  },
  methods: {
    getZoneList() {
      const model = {
        wsName: "GetZoneList",
      };

      wsUtils.ApiGet(model, (data) => {
        this.zoneList = data;
        this.loadingZones = false;
      });
    },
    getRingList() {
      const model = {
        wsName: "GetRingList",
      };

      wsUtils.ApiGet(model, (data) => {
        this.ringList = data;
        this.loadingRings = false;
      });
    },
    getAttributesAutofill(zoneID, ringID) {
      this.loadingAttributes = true;
      return new Promise((resolve, reject) => {
        const model = {
          wsName: "GetAttributesAutofill",
          data: {
            zoneID,
            ringID,
          },
        };

        wsUtils.ApiPost(model, (data) => {
          if (data) {
            resolve(data);
            this.loadingAttributes = false;
          } else {
            reject("No data returned");
          }
        });
      });
    },
    async onZoneOrRingChange(index, type, obj) {
      if (type === "brand") {
        const row = this.rows[index];
        const zoneID = obj && obj.zoneID ? obj.zoneID : row.zoneID;
        const ringID = obj && obj.ringID ? obj.ringID : row.ringID;
        if (zoneID && ringID) {
          try {
            const data = await this.getAttributesAutofill(zoneID, ringID, type);
            const transformedData = data.map((item) => ({
              AttributeLabel: item.AttributeLabel,
              attributeList: item.AttributeSynonym.map((synonym, index) => ({
                id: index,
                zoneID: item.ZoneID,
                ringID: item.RingID,
                attributeName: synonym.synonymLabel,
              })),
            }));
            this.$set(this.attributeOptions, index, transformedData);
          } catch (error) {
            console.error("Error fetching brand attributes:", error);
          }
        } else {
          // Reset attribute options if zone or ring is not selected
          this.$set(this.attributeOptions, index, []);
          this.rows[index].attributeID = undefined;
        }
      } else if (type === "distractor") {
        const row = this.distractorRows[index];
        const zoneID = obj && obj.zoneID ? obj.zoneID : row.zoneID;
        const ringID = obj && obj.ringID ? obj.ringID : row.ringID;
        if (zoneID && ringID) {
          try {
            const data = await this.getAttributesAutofill(zoneID, ringID, type);
            const transformedData = data.map((item) => ({
              AttributeLabel: item.AttributeLabel,
              attributeList: item.AttributeSynonym.map((synonym, index) => ({
                id: index,
                zoneID: item.ZoneID,
                ringID: item.RingID,
                attributeName: synonym.synonymLabel,
              })),
            }));
            this.$set(this.distractorAttributeOptions, index, transformedData);
          } catch (error) {
            console.error("Error fetching distractor attributes:", error);
          }
        } else {
          // Reset attribute options if zone or ring is not selected
          this.$set(this.distractorAttributeOptions, index, []);
          this.distractorRows[index].attributeID = undefined;
        }
      }
    },
    onAttributeChange(value, index, type) {
      const [attributeName, ringID, zoneID] = value.split("_");
      if (type === "brand") {
        this.attributes.brand_Positioning.attributeList[index].attributeName =
          attributeName;
        this.attributes.brand_Positioning.attributeList[index].zoneID =
          Number(zoneID);
        this.attributes.brand_Positioning.attributeList[index].ringID =
          Number(ringID);
      } else if (type === "distractor") {
        this.attributes.distractor_Positioning.attributeList[
          index
        ].attributeName = attributeName;
        this.attributes.distractor_Positioning.attributeList[index].zoneID =
          Number(zoneID);
        this.attributes.distractor_Positioning.attributeList[index].ringID =
          Number(ringID);
      }
    },
    validateInputs() {
      const isDisabled =
        !this.attributes.brand_Positioning.name ||
        !this.attributes.brand_Positioning.definitions ||
        this.attributes.brand_Positioning.attributeList.some(
          (attr) => !attr.zoneID || !attr.ringID || !attr.attributeName
        ) ||
        !this.attributes.distractor_Positioning.name ||
        !this.attributes.distractor_Positioning.definitions ||
        this.attributes.distractor_Positioning.attributeList.some(
          (attr) => !attr.zoneID || !attr.ringID || !attr.attributeName
        );

      this.$emit("btn-next-disabled", isDisabled);
    },
    async validateAndProcessAttributes() {
      const brandAttributeList =
        this.attributes.brand_Positioning.attributeList;
      const distractorAttributeList =
        this.attributes.distractor_Positioning.attributeList;

      for (let index = 0; index < brandAttributeList.length; index++) {
        const obj = brandAttributeList[index];

        if (obj.attributeName.trim() !== "") {
          const objToPass = obj || {
            zoneID: this.rows[index].zoneID,
            ringID: this.rows[index].ringID,
          };
          await this.onZoneOrRingChange(index, "brand", objToPass);
        }
      }
      for (let index = 0; index < distractorAttributeList.length; index++) {
        const obj = distractorAttributeList[index];

        if (obj.attributeName.trim() !== "") {
          const objToPass = obj || {
            zoneID: this.rows[index].zoneID,
            ringID: this.rows[index].ringID,
          };
          await this.onZoneOrRingChange(index, "distractor", objToPass);
        }
      }
    },
    initializeRows() {
      this.rows.forEach((row, index) => {
        const attribute =
          this.attributes.brand_Positioning.attributeList[index];
        if (attribute) {
          this.$set(this.rows, index, {
            zoneID: attribute.zoneID || undefined,
            ringID: attribute.ringID || undefined,
            attributeID: attribute.attributeName || undefined,
          });

          if (attribute.zoneID && attribute.ringID) {
            this.getAttributesAutofill(
              attribute.zoneID,
              attribute.ringID,
              "brand"
            )
              .then((data) => {
                const transformedData = data.map((item) => ({
                  AttributeLabel: item.AttributeLabel,
                  attributeList: item.AttributeSynonym.map(
                    (synonym, index) => ({
                      id: index,
                      zoneID: item.ZoneID,
                      ringID: item.RingID,
                      attributeName: synonym.synonymLabel,
                    })
                  ),
                }));
                this.$set(this.attributeOptions, index, transformedData);
              })
              .catch((error) => {
                console.error("Error fetching brand attributes:", error);
              });
          }
        }
      });
      this.distractorRows.forEach((row, index) => {
        const attribute =
          this.attributes.distractor_Positioning.attributeList[index];
        if (attribute) {
          this.$set(this.distractorRows, index, {
            zoneID: attribute.zoneID || undefined,
            ringID: attribute.ringID || undefined,
            attributeID: attribute.attributeName || undefined,
          });

          if (attribute.zoneID && attribute.ringID) {
            this.getAttributesAutofill(
              attribute.zoneID,
              attribute.ringID,
              "distractor"
            )
              .then((data) => {
                const transformedData = data.map((item) => ({
                  AttributeLabel: item.AttributeLabel,
                  attributeList: item.AttributeSynonym.map(
                    (synonym, index) => ({
                      id: index,
                      zoneID: item.ZoneID,
                      ringID: item.RingID,
                      attributeName: synonym.synonymLabel,
                    })
                  ),
                }));
                this.$set(
                  this.distractorAttributeOptions,
                  index,
                  transformedData
                );
              })
              .catch((error) => {
                console.error("Error fetching brand attributes:", error);
              });
          }
        }
      });
    },
  },
  mounted() {
    this.validateInputs();
  },
  created() {
    this.getZoneList();
    this.getRingList();
    // this.validateAndProcessAttributes();
    this.initializeRows();
  },
};
</script>

<style lang="scss" scoped></style>
