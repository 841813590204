<template>
  <div class="box-wrapper">
    <StimuliTitle
      title="To test your distinctive brand assets using our Implicit exercise, please enter your client and competitor brand (the competitor brand will serve as a distractor)."
    ></StimuliTitle>
    <div class="box-wrapper__inner">
      <div class="input-group">
        <label> Client Brand </label>
        <div class="position-r">
          <Skeleton :borderRadius="16" v-if="loading"></Skeleton>
          <a-input v-model="fingerprint.clientDetails.brandName" />
        </div>
      </div>
      <div class="input-group">
        <label> Competitor Brand </label>
        <div class="position-r">
          <Skeleton :borderRadius="16" v-if="loading"></Skeleton>
          <a-input v-model="fingerprint.competitorDetails.brandName" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StimuliTitle from "@/components/packTestSurvey/StimuliTitle.vue";
import Skeleton from "@/components/general/Skeleton.vue";
export default {
  name: "TargetAndDistractorBrand",
  components: { StimuliTitle, Skeleton },
  props: {
    fingerprint: Object,
    loading: Boolean,
  },
  data() {
    return {
      clientBrand: "",
      competitorBrand: "",
      showSkeleton: false,
    };
  },
  computed: {},
  watch: {
    fingerprint: {
      handler() {
        this.validateInputs();
      },
      deep: true,
    },
  },
  methods: {
    validateInputs() {
      const isDisabled =
        !this.fingerprint.clientDetails.brandName.trim() ||
        !this.fingerprint.competitorDetails.brandName.trim();
      this.$emit("btn-next-disabled", isDisabled);
    },
  },
  mounted() {
    this.validateInputs();
  },
  created() {},
  activated() {},
};
</script>

<style lang="scss" scoped></style>
