<template>
  <div class="box-wrapper">
    <StimuliTitle
      title="Please enter your client assets for your primes. You can upload between 3 to 4 assets. For accurate results, ensure that your images are debranded, black and white (unless testing your brand’s colour palette) and isolated. We advise against combination assets."
    ></StimuliTitle>
    <ImageUpload
      v-for="(image, index) in fingerprint.clientDetails.assetDetails"
      :key="index"
      :image="image"
      :surveyID="fingerprint.surveyID"
      @set-image="setImage($event, index)"
    ></ImageUpload>
  </div>
</template>

<script>
import StimuliTitle from "@/components/packTestSurvey/StimuliTitle.vue";
import ImageUpload from "@/components/brandFingerprint/ImageUpload.vue";

export default {
  name: "ClientPrimes",
  components: { StimuliTitle, ImageUpload },
  props: {
    fingerprint: Object,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {
    fingerprint: {
      handler() {
        this.validateInputs();
      },
      deep: true,
    },
  },
  methods: {
    setImage(imageURL, index) {
      this.fingerprint.clientDetails.assetDetails[index].upload_assets =
        imageURL;
    },
    validateInputs() {
      const filledImages = this.fingerprint.clientDetails.assetDetails.filter(
        (image) =>
          image.upload_assets.trim() !== "" &&
          image.asset_description.trim() !== ""
      );
      const isNextDisabled = filledImages.length < 3;
      this.$emit("btn-next-disabled", isNextDisabled);
    },
  },
  mounted() {
    this.validateInputs();
  },
  created() {},
  activated() {},
};
</script>

<style lang="scss" scoped></style>
